<template>
  <div class="sms_template_list_add">
    <el-card>
      <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
      <div class="flex">
        <el-form :model="form" label-width="120px" style="flex:1">
          <el-form-item label="标题">
            <el-col :span="12">
              <el-input
                  placeholder="请输入通知内容"
                  v-model="form.title"
              ></el-input>
            </el-col>

          </el-form-item>
          <el-form-item label="通知内容">
            <div>
              <el-col :span="12">
                <el-input
                    placeholder="请输入通知内容"
                    :rows="8"
                    type="textarea"
                    v-model="form.content"
                    show-word-limit
                    maxlength="490"
                ></el-input>
              </el-col>
            </div>
<!--            <div style="clear: both; line-height: 1.8; width: 50%;padding-top: 10px;color: #fda400;">-->
<!--              <div class="tips">-->
<!--                当前 <span>{{ form.content.length }}</span> 字，预计发送条数约为-->
<!--                <span>{{ contentArticle }}</span>-->
<!--                条。实际发送时，签名、变量长度会影响总字数。具体计费条数请<span-->
<!--              >以模板审核通过后发送测试为基准</span-->
<!--              >-->
<!--              </div>-->
<!--              <div>-->
<!--                【注意事项】<br />-->
<!--                1、文案前面不用加[扬帆出海]，发出时会自动加上 <br />-->
<!--                2、文案最后需要手动加上“拒收请回复R” <br />-->
<!--                3、链接前后需要空格<br/>-->
<!--                4、文案中不要出现【】符号-->

<!--              </div>-->
<!--              <span style="color: red ">-->
<!--                         示例： CJ游友必打卡！[7.24-7.27]与扬帆出海开启4日上海GAME之旅！1场游戏主题峰会+3大主题派对，集结数百家海内外头部厂商，汇聚5000+游戏行业出海精英，资讯/干货/人脉/合作/壕礼统统拿下！即刻报名： http://yfch.cc/eP88m9 ，拒收请回复R-->
<!--                        </span>-->
<!--            </div>-->

          </el-form-item>


          <el-form-item label="通知类型">
            <el-select v-model="form.category" size="medium" placeholder="请选择通知类型">
              <el-option
                  v-for="item in noticeList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="展会">
            <el-select v-model="form.exhibitionId" size="medium" placeholder="请选择展会">
              <el-option
                  v-for="item in exhibitionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="展区">
            <el-select v-model="form.exhibitionArea" multiple size="medium" placeholder="请选择展区">
              <el-option
                  v-for="item in exhibitionAreaList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资料文档">
            <up-file :value.sync="form.file" ></up-file>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="add">{{ "保存" }}</el-button>
            <!-- <el-button size="small" @click="preview">{{ "预览" }}</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-dialog :visible.sync="show" width="750px"> </el-dialog>
  </div>
</template>

<script>
import fuzzySelection from "@/components/fuzzySelection";
export default {
  components: {
    fuzzySelection,
  },
  data() {
    return {
      form: {
        content: "",
        file: "",
        title:"",
        id: "",
        exhibitionId:"",
        category:1,
        exhibitionArea:[]
      },
      exhibitionList:[],
   exhibitionAreaList:[],
      noticeList:[
          {
        key:1,
        value:'普通通知'
         },
        {
        key:2,
        value:'展商须知'
      }
      ]

    };
  },
  watch: {},
  created() {
    sessionStorage.setItem('action', 'enterprise')
    this.id = this.$route.query.id;
    if(this.id){
      this.getForm();
    }
    this.getExhibitionList()
    this.getExhibitionAreaList()
  },
  methods: {
    async getExhibitionList(){
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionList?page=1&pageSize=100");
      if(res.errorCode == 200){
        this.exhibitionList=res.data.list
        this.form.exhibitionId=this.exhibitionList[0].id
      }
    },
    async getExhibitionAreaList(){
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionAreaList");
      if(res.errorCode == 200){
        this.exhibitionAreaList=res.data
      }
      console.log(this.exhibitionList)
    },
    goBack() {
      this.$router.go(-1);
    },
    async getForm() {
      if (!this.id) return;
      let { data: res } = await this.$http.get("/admin/Exhibition/getNoticeDetail", { params: { id: this.id } });
      if (res.errorCode == 200) {
        console.log(res.data)
        this.form = res.data;
        this.form.exhibitionArea=res.data?.exhibitionArea ? res.data.exhibitionArea?.split(',')[0]=='Def'?[]:res.data.exhibitionArea?.split(','):[]
        console.log(this.form)
      }
    },
    pleaseSelect() {},
    showEdit(row) {
      this.form.topId = row.id;
      this.form.topTitle = row.title;
    },
    async add() {
      var url = "/admin/Exhibition/addNotice";
      if (this.id) {
        url = "/admin/Exhibition/editNotice";
      }
      this.form.exhibitionArea=this.form.exhibitionArea.join(',')
      let { data: res } = await this.$http.post(url,this.form );
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.$router.go(-1);
      }
    },
    preview() {
      this.show = true;
    },
    select() {
      this.$refs.pop.init(this.form.topType, this.form.topId);
    },
  },
  computed: {
    contentArticle() {
      var length = this.form.content.length;
      return Math.ceil(length / 67);
    },
  },
};
</script>

<style lang="less" scoped>
.for {
  transform: scale(0.5);
  transform-origin: 0 0;
}
.tips {
  color: gray;
  span {
    color: red;
  }
}
</style>
